<template>
  <section
    id="try-it"
    class="grey lighten-3 px-3"
  >
    <section-header
      header="Kontakt"
      sub-header="Melden Sie sich!"
      text="Treten Sie noch heute mit uns in Kontakt! Füllen Sie das Formular aus und wir melden uns bei Ihnen für ein kostenloses, unverbindliches Erstgespräch!"
    />

    <v-card
      outlined
      class="pa-4 mx-auto"
      max-width="500"
    >
      <v-form>
        <v-text-field
          outlined
          label="Name"
          v-model="name"
        />
        <v-text-field
            outlined
            label="Restaurant"
            v-model="restaurant"
        />
        <v-text-field
          outlined
          label="E-Mail"
          v-model="mail"
        />
        <v-text-field
            outlined
            label="Telefon"
            v-model="phone"
        />
        <v-btn
          color="success"
          block
          depressed
          large
          :disabled="!phone || !mail || !name"
          tile
          @click="handleSubmit"
        >
          Abschicken
        </v-btn>
      </v-form>
      <v-overlay
          :absolute=true
          :value="overlay"
      >
        <v-layout align-center justify-center>
          <v-progress-circular
              indeterminate
              size="50"
              width="8"
              color="success"
              v-if="!success"
          ></v-progress-circular>
          <h1 v-else>
            Vielen Dank! <br>
          </h1>
        </v-layout>
      </v-overlay>
    </v-card>
  </section>
</template>
<script>
const axios = require('axios')
export default {
  data: () => ({
    overlay: false,
    success: false,
    name: '',
    mail: '',
    phone: '',
    restaurant:''
  }),
  methods: {
    async handleSubmit() {
      this.overlay = true
      axios.post('https://europe-west3-bestellen-d01fa.cloudfunctions.net/notifyUs', {
        name: this.name,
        restaurant: this.restaurant,
        mail: this.mail,
        phone: this.phone,
      })
          .then((response) => {
            console.log(response)
            this.success = true
          })
          .catch((error) => {
            this.overlay = false
            console.log(error);
          });
    }
  }
}

</script>
