<template>
  <v-img
    v-if="namespace"
    height="100vh"
    src="/static/delivery-3.jpg"
    lazy-src="/static/delivery-3-sm.jpg"
    class="white--text"
    gradient="to top, rgba(13, 3, 29, .70), rgba(25, 25, 38, .75)"
  >
    <v-container fill-height>
      <v-row
        align="center"
        class="pt-5 px-3"
      >
        <v-sheet
          color="transparent"
          dark
          max-width="500"
        >
          <h1
            class="mb-4"
            :class="$vuetify.breakpoint.mdAndUp ? 'display-2' : 'display-1'"
          >
            Ihr Restaurant.<br> Ihr Gewinn.
          </h1>

          <p class="title font-weight-light">
            Wir helfen ihnen dabei Ihr volles Potenzial auszuschöpfen. Schluss mit überhöhten Provisionen und Gebühren.
          </p>

          <div class="py-4" />

          <v-row
            class="ma-0"
            justify="center"
            justify-md="start"
          >
            <v-btn
              class="mr-md-3 my-2"
              color="white"
              href="#pricing"
              large
              min-width="175"
              outlined
              rounded
              @click.prevent="$vuetify.goTo('#features')"
            >
              Mehr erfahren
            </v-btn>

            <v-btn
              class="text--primary my-2"
              color="white"
              href="#try-it"
              large
              min-width="175"
              rounded
              @click.prevent="$vuetify.goTo('#try-it')"
            >
              Kontakt
            </v-btn>
          </v-row>
        </v-sheet>
      </v-row>
    </v-container>
  </v-img>
</template>

<script>
  export default {
    computed: {
      namespace () {
        return this.$route.name
      }
    }
  }
</script>
