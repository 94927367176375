<template>
  <section
    id="home"
    class="pa-0"
  >
    <hero />

    <features />


    <pricing />
    <demo />

    <try-it ref="scrollToMe" />
  </section>
</template>


<script>
import TryIt from '@/components/sections/TryIt'
import Demo from '@/components/sections/Demo'
import Features from '@/components/sections/Features'
import Hero from '@/components/sections/Hero'
import Pricing from '@/components/sections/Pricing'

  export default {
    metaInfo () {
      return {
        title: ''
      }
    },
    props: {
      interest: {
        type: Boolean,
        default: false
      },
    },

    components: {
      Demo,
      Features,
      Hero,
      Pricing,
      TryIt,
    },

    data: () => ({
      items: ['/static/server-parallax.png']
    }),
    mounted() {
      if(this.interest){
        setTimeout(() => {
          const el = this.$refs.scrollToMe.$el;
          console.log(el);
          el && el.scrollIntoView({ behavior: 'smooth' , block: 'start'});

        }, 450);
      }
    },

    computed: {
      cardFeatures () {
        return []
      },
      listFeatures () {
        return []
      },
      testimonials () {
        return []
      }
    }
  }
</script>
