<template>
  <section
    id="pricing"
    class="px-3"
  >
    <section-header
      sub-header="Preise"
    />

    <v-container
      mt-5
      pa-0
    >
      <v-row>
        <!--<v-col
          cols="12"
          class="text-center mb-5"
        >
          <v-btn-toggle
            v-model="frequency"
            rounded
          >
            <v-btn
              max-height="40"
              min-width="50%"
            >
              Monatlich
            </v-btn>
            <v-btn
              max-height="40"
              min-width="50%"
            >
              Jährlich
            </v-btn>
          </v-btn-toggle
        </v-col>>-->
        <v-col
          v-for="(plan, i) in plans"
          :key="i"
          cols="12"
          md="4"
        >
          <v-card
            :elevation="i === 1 ? 12 : 0"
            :outlined="i !== 1"
            height="100%  "
            class="v-card--plan text-center pb-3"
          >
            <v-toolbar
              color="grey lighten-5"
              flat
            >
              <v-spacer />
              <div v-text="plan.name" />
              <v-spacer />
            </v-toolbar>

            <v-row>
              <v-col
                cols="12"
                class="v-card--plan__price pa-5"
              >
                <div
                  :class="i === 1 ? 'success--text' : ''"
                  class="d-inline-block"
                >
                  <span
                    class="display-3 font-weight-bold"
                    v-text="(frequency ? plan.yearly : plan.monthly)"
                  />
                </div>
                <span class="caption">
                  € /{{ frequency ? 'Jahr' : 'Monat' }}
                </span>
              </v-col>
            </v-row>
            <v-card-text class="mb-4">
              <div
                v-for="(feature, j) in plan.features"
                :key="j"
                v-text="feature"
              />
            </v-card-text>
            <v-card-text>
              <v-btn
                :color="i === 1 ? 'success' : 'primary'"
                depressed
                tile
                @click.prevent="$vuetify.goTo('#try-it')"
              >
                Weiter
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  export default {
    data: () => ({
      frequency: 0,
      plans: [
        {
          name: 'Basis',
          monthly: 29,
          yearly: 228,
          features: [
            'Null Provision',
            'Eigene Webpräsenz',
            'Digitale Speisekarte',
            'Bestellvermittlung zum Abholen',
          ]
        },
        {
          name: 'Plus',
          monthly: 49,
          yearly: 468,
          features: [
            '+ Promo-Material',
            '+ Individuelles Design',
            '+ Komplette Bestellplatform',
          ]
        },
        {
          name: 'Premium',
          monthly: 59,
          yearly: 708,
          features: [
              '+ Ihre eigene App',
            '+ Android und iOS Unterstützung',
              '+ Reservierungen'

          ]
        }
      ]
    })
  }
</script>

<style lang="sass">
  .v-card--plan
    &__price
      div
        vertical-align: text-bottom

        > sup
          vertical-align: super

        > span
          vertical-align: middle

        + span
          vertical-align: super
</style>
